import React from 'react';
import './App.css';
import Leaderboard from './Leaderboard';

function App() {
  return (
    <div>
      <Leaderboard/>
    </div>
  );
}

export default App;
