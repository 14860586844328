import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import { Table, Tag } from 'antd';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: 'ec-stonker.firebaseapp.com',
  projectId: 'ec-stonker',
  storageBucket: 'ec-stonker.appspot.com',
  messagingSenderId: process.env.REACT_APP_MSI,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MID,
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

function Leaderboard() {
  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      sorter: (a, b) => {
        if (a.ticker < b.ticker) {
          return -1;
        } if (a.ticker > b.ticker) {
          return 1;
        }
        return 0;
      },
      render: function f(text) { return <b>{text}</b> },
    },
    {
      title: 'Today\'s Rank',
      dataIndex: 'todayRank',
      sorter: (a, b) => a.todayRank - b.todayRank,
    }, {
      title: 'Yesterday\'s Rank',
      dataIndex: 'yesterdayRank',
      sorter: (a, b) => a.yesterdayRank - b.yesterdayRank,
    },
    {
      title: 'Pct Change',
      dataIndex: 'pctChange',
      align: 'center',
      sorter: (a, b) => a.pctChange - b.pctChange,
      render: function f(pctChange) {
        if (pctChange === null) {
          return (
            <Tag color="gray">missing</Tag>
          )
        }
        let color = pctChange < 0.0 ? "red" : "green"
        return (
          <Tag color={color}>
            {pctChange.toFixed(2)}%
          </Tag>
        )
      },
    }];

  useEffect(() => {
    const db = firebase.firestore();
    const leaderboard = db.collection('web').doc('leaderboard');
    leaderboard.get().then((doc) => {
      if (doc.exists) {
        const docData = doc.data();
        setData(docData.rows);
      }
    }).catch((error) => {
      console.error('Error getting document:', error);
    });
  }, []);

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Stonker Leaderboard</h1>
      <Table
        bordered
        pagination={false}
        rowKey={(record) => record.ticker}
        dataSource={data}
        columns={columns}
        style={{ margin: '5%' }}
      />
    </div>
  );
}

export default Leaderboard;
